import React from "react";
import { PageLayout, LocationsSorted, ContactForm } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness } = data;

    return (
      <WebsiteLayout>
        <PageLayout className={"locations-page"} hero={null}>
          <Grid stackable={true} columns={1}>
            <Grid.Column>
              <LocationsSorted
                locations={fishermanBusiness.locations}
                searchHeader={"Locations"}
                searchHeaderAs={"h1"}
                showIcons={false}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true}>
            <Grid.Column width={4} />
            <Grid.Column width={8}>
              <ContactForm
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Contact Us"}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
            <Grid.Column width={4} />
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      locations {
        phoneNumber
        email
        street
        city
        state
        zipCode
        hours {
          day
          open
          close
          label
        }
        _id
      }
      primaryLocation {
        _id
      }
      _id
      type
    }
  }
`;
